/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Load fonts
import "@fontsource/caladea"
import "@fontsource/roboto"

// Allow for correct image loading on Safari
// see: https://dev.to/arnonate/gatsby-images-and-safari-be8
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
